html {
  position:relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: none;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@supports (-webkit-touch-callout: none) {
  html {
      overscroll-behavior-x: none;
  }
}
