.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/**
  Arrange
**/
body::-webkit-scrollbar {
  width: 0;
  background: #ffffff00;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  color: #000;
  background-color: #161d22;
}

::selection {
  color: #2ad1ff;
  background-color: #1e2a35;
}

::-moz-selection {
  color: #2ad1ff;
  background-color: #1e2a35;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
}

h1 {
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: calc(24px + 2vmin);
  font-weight: 800;
  text-align: center;
}

h2 {
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: calc(18px + 2vmin);
  font-weight: 800;
  text-align: center;
}

h3 {
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: calc(14px + 2vmin);
  font-weight: 800;
  text-align: center;
}

h4 {
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: calc(16px + 1vmin);
  font-weight: 800;
  text-align: center;
}

h5 {
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: calc(12px + 1vmin);
  font-weight: 800;
  text-align: center;
}

p {
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

label.copyright {
  font-size: calc(10px + 1vmin);
  color: #6c757d;
  font-weight: 800;
  margin: 5% 0 3% 0;

  @media (min-width: 0px) and (max-width: 960px) {
      font-size: calc(14px + 1vmin);
      margin: 10% 0;
    }
}

label span {
  color: #000;
  font-weight: 800;
}

hr {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

hr.line {
  background-color: #ffffff;
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1em;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1em;
}

hr.line:before {
  content: "";
  background: linear-gradient(to right, transparent, #ced4da, transparent);
  position: absolute;
  top: 50%;
  width: 80%;
  height: 1px;
}

hr.linew {
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1em;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1em;
}

hr.linew:before {
  content: "";
  background: linear-gradient(to right, transparent, #ced4da, transparent);
  position: absolute;
  width: 80%;
  height: 1px;
}
